<script>
/**
 * Product schedule sub view | Sub view of a Product
 *
 * @property {Date} replyDate
 * @property {String} replyStatus
 * @property {Date} replyAt
 * @property {String} replyBy
 * @property {Boolean} replyAllowed
 **/
import Table from '@/components/Table.vue'
import Icon from '@/components/Icon.vue'
import InteractionLink from '@/components/InteractionLink.vue'
import DateInput from '@/components/DateInput.vue'
import IconButton from '@/components/IconButton.vue'
import { axiosService } from '@/mixins/axiosService'
import { dateTimeHelper } from '@/mixins/dateTimeHelper'

export default {
    name: 'ProductSchedule',
    mixins: [
        axiosService,
        dateTimeHelper
    ],
    components: {
        Table,
        Icon,
        InteractionLink,
        DateInput,
        IconButton
    },
    props: {
        productId: [String, Number]
    },
    data () {
        return {
            productInfo: this.$attrs.productInfo || { id: this.productId },
            milestones: [],
            editingMilestones: [],
            milestoneReplyMessages: {
                LEGAL_LICENCE_IMPRIMATUR: {
                    title: 'product.schedule.legalLicenceImprimaturPopup.title',
                    text: 'product.schedule.legalLicenceImprimaturPopup.text',
                    confirmButtonText: 'product.schedule.legalLicenceImprimaturPopup.confirmButtonText'
                }
            },
            showSpinner: true
        }
    },
    methods: {
        getMilestones () {
            this.axiosGet(
                `schedule/${this.productInfo.id}`,
                this.$tc('product.schedule.getMilestonesError'))
                .then(milestones => {
                    milestones = milestones.filter(milestone =>
                        !milestone.replyStatus ||
                        milestone.replyStatus.toLowerCase() !== this.$tc('product.schedule.reportingHiddenKey')
                    )
                    milestones.forEach(milestone => this.setupMilestoneInformation(milestone))
                    this.milestones.push(...milestones)
                    this.showSpinner = false
                })
                .catch(() => {
                    this.showSpinner = false
                })
        },

        setupMilestoneInformation (milestone) {
            milestone.itemTypeTooltip = this.$tc('product.schedule.table.itemTypes.SCHEDULE')
            milestone.nameDisplayText = this.getNameDisplayText(milestone)
            milestone.calculated = milestone.editable
            milestone.daysUntilDeadline = this.getRemainingDaysUntilDeadline(milestone)
            milestone.statusText = this.getStatusText(milestone)
            milestone.statusDisplayText = this.getStatusDisplayText(milestone)
            milestone.replyDeadlineDisplayText = this.getFormattedShortDate(milestone.replyDeadline, this.locale)
        },

        closeActiveInputs () {
            this.editingMilestones = []
        },

        getNameDisplayText (milestone) {
            return milestone.stepName
                ? milestone.stepName
                : this.$tc('product.schedule.table.processNames.unknown')
        },

        isMilestoneReportingCompleted (milestone) {
            return milestone.replyDate !== null || milestone.replyAt !== null
        },

        getRemainingDaysUntilDeadline (milestone) {
            const daysLeft = this.calculateDaysLeftToDeadlineRoundedDown(milestone.replyDeadline)
            if (this.isMilestoneReportingCompleted(milestone)) {
                // In order to sort all finished milestones after pending ones while keeping their sequence
                return daysLeft + 1000000
            }
            return daysLeft
        },

        getRemainingDaysDisplayText (milestone) {
            if (milestone.daysUntilDeadline > 0) {
                return this.$t('product.schedule.table.deadlineReachedIn', [milestone.daysUntilDeadline])
            }
            if (milestone.daysUntilDeadline < 0) {
                return this.$t('product.schedule.table.deadlineExceeded', [-milestone.daysUntilDeadline])
            }
            return this.$tc('product.schedule.table.deadlineReached')
        },

        setMilestoneEditing (milestone, editing) {
            const index = this.editingMilestones.indexOf(milestone.id)
            if (editing && index < 0) {
                this.editingMilestones.push(milestone.id)
            } else if (!editing && index >= 0) {
                this.editingMilestones.splice(index, 1)
            }
        },

        getStatusIcon (milestone) {
            if (!this.isMilestoneReportingCompleted(milestone)) {
                return 'far fa-clock'
            }

            return milestone.replyStatus.toLowerCase() === this.$tc('product.schedule.milestoneDoneKey')
                ? 'fas fa-flag-checkered'
                : 'far fa-envelope'
        },

        getStatusText (milestone) {
            if (!this.isMilestoneReportingCompleted(milestone)) {
                return this.milestoneHasDeadline(milestone)
                    ? milestone.daysUntilDeadline
                    : this.$tc('product.schedule.table.productContentInProgressNoDeadline')
            }
            if (milestone.replyStatus.toLowerCase() === this.$tc('product.schedule.milestoneDoneKey')) {
                return this.$t('product.schedule.table.milestoneDoneAt', [milestone.replyDate])
            }
            return milestone.replyAt && milestone.replyBy
                ? this.$t('product.schedule.table.reportingStatusDoneAtBy', [milestone.replyAt, milestone.replyBy])
                : this.$tc('product.schedule.table.reportingStatusDone')
        },

        getStatusDisplayText (milestone) {
            if (!this.isMilestoneReportingCompleted(milestone)) {
                return this.milestoneHasDeadline(milestone)
                    ? this.getRemainingDaysDisplayText(milestone)
                    : this.$tc('product.schedule.table.productContentInProgressNoDeadline')
            }
            if (milestone.replyStatus.toLowerCase() === this.$tc('product.schedule.milestoneDoneKey')) {
                return this.$t('product.schedule.table.milestoneDoneAt', [this.getFormattedShortDate(milestone.replyDate, this.locale)])
            }
            return milestone.replyAt && milestone.replyBy
                ? this.$t('product.schedule.table.reportingStatusDoneAtBy', [
                    this.getFormattedShortDate(milestone.replyAt, this.locale),
                    milestone.replyBy
                ])
                : this.$tc('product.schedule.table.reportingStatusDone')
        },

        getStatusIconType (milestone) {
            if (this.isMilestoneReportingCompleted(milestone)) {
                return 'success'
            }
            if (milestone.daysUntilDeadline > 0 && milestone.daysUntilDeadline <= 7) {
                return 'warning'
            }
            if (milestone.daysUntilDeadline <= 0) {
                return 'error'
            }
            return 'default'
        },

        milestoneHasDeadline (milestone) {
            return milestone.replyDeadline
        },

        isMilestoneCalculated (milestone) {
            return milestone.editable
        },

        isMilestoneInEditMode (milestone) {
            return this.editingMilestones.includes(milestone.id)
        },

        isMilestoneDeadlineChangedManually (milestone) {
            return milestone.editedBy && milestone.editedAt
        },

        isReplyButtonVisible (milestone) {
            return !this.isMilestoneReportingCompleted(milestone) &&
                !milestone.calculated
        },

        isReplyButtonDisabled (milestone) {
            return !milestone.replyAllowed
        },

        updateDeadline (inputChangeEventData, milestone) {
            const newDeadline = inputChangeEventData.value
            const api = `schedule/${this.productInfo.id}/${milestone.id}`
            const data = milestone
            data.replyDeadline = newDeadline

            this.axiosPut(
                api,
                data,
                this.$t('product.schedule.saveMilestoneDeadlineError', [milestone.nameDisplayText]),
                {
                    standard: this.$t('product.schedule.saveMilestoneDeadlineSuccess.standard', [milestone.nameDisplayText]),
                    short: this.$t('product.schedule.saveMilestoneDeadlineSuccess.short', [milestone.nameDisplayText])
                })
                .then(updatedMilestone => {
                    milestone.replyDeadline = newDeadline
                    milestone.daysUntilDeadline = this.getRemainingDaysUntilDeadline(milestone)
                    milestone.statusText = this.getStatusText(milestone)
                    milestone.statusDisplayText = this.getStatusDisplayText(milestone)
                    milestone.replyDeadlineDisplayText = this.getFormattedShortDate(milestone.replyDeadline, this.locale)
                    milestone.editedAt = updatedMilestone.editedAt
                    milestone.editedBy = updatedMilestone.editedBy
                    this.setMilestoneEditing(milestone, false)
                })
                .catch(() => {
                    this.setMilestoneEditing(milestone, false)
                })
        },

        replyMilestoneToSap (milestone) {
            function executeReply (appContext) {
                appContext.axiosPost(
                    `schedule/${appContext.productInfo.id}/${milestone.id}/reply`,
                    null,
                    appContext.$t('product.schedule.replyMilestoneError', [milestone.nameDisplayText]),
                    {
                        standard: appContext.$t('product.schedule.replyMilestoneSuccess.standard', [milestone.nameDisplayText]),
                        short: appContext.$tc('product.schedule.replyMilestoneSuccess.short')
                    })
                    .then(updatedMilestones => {
                        const milestoneIds = appContext.milestones.map(_milestone => _milestone.id)
                        updatedMilestones.forEach(updatedMilestone => {
                            const index = milestoneIds.indexOf(updatedMilestone.id)
                            appContext.setupMilestoneInformation(updatedMilestone)
                            appContext.milestones.splice(index, 1, updatedMilestone)
                            appContext.$forceUpdate()
                        })
                    })
                    .catch(() => {})
            }

            if (this.milestoneReplyMessages[milestone.name]) {
                const milestoneReplyMessage = this.milestoneReplyMessages[milestone.name]
                this.$swal({
                    title: this.$tc(milestoneReplyMessage.title),
                    html: this.$tc(milestoneReplyMessage.text),
                    icon: 'info',
                    showConfirmButton: true,
                    confirmButtonText: this.$tc(milestoneReplyMessage.confirmButtonText),
                    showCancelButton: true,
                    cancelButtonText: this.$tc('generals.cancel'),
                    allowOutsideClick: false,
                    allowEscapeKey: false
                }).then(result => {
                    if (result.isConfirmed) {
                        executeReply(this)
                    }
                })
            } else {
                executeReply(this)
            }
        }
    },
    computed: {
        locale () {
            return this.$global.localization.locale
        },

        getTableConfig () {
            return [{
                key: 'itemType',
                filterKey: 'itemTypeTooltip',
                sortKey: 'itemTypeTooltip',
                label: this.$tc('product.schedule.table.type'),
                filterable: true,
                sortable: true,
                alignment: 'left',
                exportable: true,
                width: 10
            }, {
                key: 'nameDisplayText',
                label: this.$tc('product.schedule.table.name'),
                filterable: true,
                sortable: true,
                alignment: 'left',
                exportable: true,
                width: 30
            }, {
                key: 'replyDeadlineDisplayText',
                sortKey: 'replyDeadline',
                label: this.$tc('product.schedule.table.replyDeadline'),
                filterable: true,
                sortable: true,
                alignment: 'left',
                exportable: true,
                width: 20
            }, {
                key: 'statusDisplayText',
                sortKey: 'statusText',
                label: this.$tc('product.schedule.table.status'),
                filterable: true,
                sortable: true,
                alignment: 'left',
                exportable: true,
                width: 35
            }, {
                key: 'action',
                label: null,
                filterable: false,
                sortable: false,
                alignment: 'right',
                exportable: false,
                width: 5
            }]
        }
    },
    mounted () {
        this.getMilestones()
    }
}
</script>

<template>
    <div class="generals-container"
         v-on:click="closeActiveInputs()">
        <div class="product-schedule-container">
            <div class="product-schedule-table-container">
                <Table table-id="productSchedule"
                       v-bind:table-config="getTableConfig"
                       v-bind:table-data="milestones"
                       v-bind:filter-placeholder="$tc('product.schedule.table.filterPlaceholder')"
                       v-bind:table-empty-message="$tc('product.schedule.table.tableEmpty')"
                       v-bind:filter-no-results-message="$tc('product.schedule.table.filterNoResults')"
                       default-sorting-key="replyDeadline"
                       v-bind:read-only=true
                       v-bind:show-spinner=showSpinner
                       v-bind:allow-fullscreen="true">
                    <template #cell(itemType)="data">
                        <Icon v-bind:tooltip="data.row.itemTypeTooltip"
                              icon-class="far fa-calendar-alt"
                              icon-type="dark"
                              v-bind:icon-large="true">
                        </Icon>
                    </template>
                    <!-- nameDisplayText: default cell content -->
                    <template #cell(replyDeadlineDisplayText)="data">
                        <DateInput v-if="isMilestoneInEditMode(data.row)"
                                   v-bind:id="`edit-deadline-input_${data.row.id}`"
                                   v-bind:default-value="data.row.replyDeadline"
                                   v-bind:is-auto-focused=true
                                   v-bind:submit-button=false
                                   v-bind:show-unsaved-changes=true
                                   class="generals-table-input"
                                   @input-close="setMilestoneEditing(data.row, false)"
                                   @input-blurred="updateDeadline($event, data.row)"
                                   @input-submit="updateDeadline($event, data.row)">
                        </DateInput>
                        <div v-else-if="milestoneHasDeadline(data.row)">
                            <Icon v-if="isMilestoneCalculated(data.row)"
                                  class="product-schedule-table-edit-icon"
                                  v-bind:icon-class="isMilestoneDeadlineChangedManually(data.row) ? 'fas fa-user-edit' : 'fas fa-calculator'"
                                  v-bind:tooltip="isMilestoneDeadlineChangedManually(data.row)
                                  ? $t('product.schedule.table.deadlineChangedManually', [getFormattedShortDate(data.row.editedAt, this.locale), data.row.editedBy])
                                  : $tc('product.schedule.table.deadlineChangePossible')">
                            </Icon>
                            <InteractionLink class="generals-table-input"
                                             v-bind:value="data.row.replyDeadlineDisplayText"
                                             v-bind:is-disabled="!data.row.editable"
                                             v-bind:is-underlined="data.row.editable"
                                             icon-class="fas fa-edit"
                                             @input-click="setMilestoneEditing(data.row, true)">
                            </InteractionLink>
                        </div>
                    </template>
                    <template #cell(statusDisplayText)="data">
                        <Icon v-if="milestoneHasDeadline(data.row)"
                              class="product-schedule-table-inline-icon m--margin-right"
                              v-bind:class="{'m--margin-right': isMilestoneReportingCompleted(data.row)}"
                              v-bind:icon-class="getStatusIcon(data.row)"
                              v-bind:icon-type="getStatusIconType(data.row)">
                        </Icon>
                        <span class="product-schedule-table-status-text"
                              v-bind:class="`m--${getStatusIconType(data.row)}`">{{data.row.statusDisplayText}}</span>
                    </template>
                    <template #cell(action)="data">
                        <IconButton v-if="isReplyButtonVisible(data.row)"
                                    v-bind:is-disabled="isReplyButtonDisabled(data.row)"
                                    v-bind:tooltip="$tc('product.schedule.table.replyMilestone')"
                                    icon-class="fas fa-envelope-open"
                                    hover-icon-class="fas fa-envelope-open-text"
                                    @button-submit="replyMilestoneToSap(data.row)">
                        </IconButton>
                    </template>
                </Table>
            </div>
        </div>
    </div>
</template>

<style scoped lang="less">
.product-schedule-container {
    position: relative;
    width: 100%;
    height: 100%;

    .product-schedule-table-container {
        height: 100%;

        .product-schedule-table-inline-icon {
            display: inline-block;
            margin: 0 0 0 -6px;

            &.m--margin-right {
                margin-right: 4px
            }
        }

        .product-schedule-table-edit-icon {
            display: inline-block;
            margin: 4px 4px 0 -8px;
        }

        .product-schedule-table-status-text {
            &.m--success {
                color: var(--color-success);
            }

            &.m--warning {
                color: var(--color-warning);
            }

            &.m--error {
                color: var(--color-error);
            }
        }

    }
}
</style>
