import appData from '@/appInfo.json'
import { i18n } from '@/i18n/i18n'
import { reactive } from 'vue'

export default function useGlobalProperties (config, keycloak) {
    const releaseNotesBaseUrlDe = 'https://cornelsen-cdp.iviews.empolisservices.com/document/version/Published/confluence-document-181395997/chapter'
    const releaseNotesBaseUrlEn = 'https://cornelsen-cdp.iviews.empolisservices.com/document/version/Published/confluence-document-181344131/chapter'

    const globals = {
        currentVersion: '2025.05.0 (13.03.2025)',
        // Never touch it
        releaseNotesUrl: {
            de: `${releaseNotesBaseUrlDe}/181400559`,
            en: `${releaseNotesBaseUrlEn}/181274593`
        },
        manualUrl: {
            de: `${releaseNotesBaseUrlDe}/181401021`,
            en: `${releaseNotesBaseUrlEn}/181402235`
        },
        currentBranches: {
            frontend: appData.BRANCH_NAME,
            backend: ''
        },
        localization: reactive(i18n.global),
        pendingRequests: 0,
        backendPath: config.BASEFLOW_BACKEND_ENDPOINT,
        websocketUrl: config.BASEFLOW_BACKEND_WS_ENDPOINT,
        editorUrl: config.EDITOR_URL,
        wwaUrl: config.WWA_URL,
        tatsEnabled: true, // PAU-2834 : set to 'false' if feature should not go live
        showMaintenanceMessage: appData.MAINTENANCE_MODE,
        keycloak: keycloak,
        mousePosition: reactive({
            x: 0,
            y: 0
        }),
        notifications: reactive({
            standard: {
                index: 0,
                list: []
            },
            reduced: {
                index: 0,
                list: []
            }
        }),
        user: reactive({
            list: [],
            current: {}
        })
    }

    return { globals }
}
